import logo from './logo.svg';
import './App.css';
import openNewWindow from './App2';


function App() {
  return (
    <div className="App">
      <iframe src={"https://demo-chatbot-san.my.connect.aws/ccp-v2"} title={"Chat"}  ></iframe>
      {/* 
      <iframe src={"https://demo-chatbot-san.my.connect.aws/ccp-v2"} title={"Chat"} style={"position:fixed; top:0; left:0; bottom:0; right:0; width:100%; height:100%; border:none; margin:0; padding:0; overflow:hidden; z-index:999999;"} ></iframe>

      <button onClick={openNewWindow()}>
  Start
       </button> */}

      {/* <iframe src={"bot.html"} title="Chat" width="100%" height="100%" style={{border: 'none'} }></iframe> */}
    </div>
    // <div className="App">
    //   <header className="App-header">
    //     <img src={"bora2.png"} alt="logo" />
    //     <p>
    //       Hottest man alive
    //     </p>
      
    //   </header>
    // </div>
  );
}

export default App;
